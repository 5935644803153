/* ./src/index.css */
@import url("https://rsms.me/inter/inter.css");
html {
    font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
    html {
        font-family: "Inter var", sans-serif;
    }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

keen-slider__slide {
    width: 300px;
    padding: 8px;
}
